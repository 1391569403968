@font-face {
  font-family: "nagios-icons";
  src: url("./nagios-icons.eot?xohfz7");
  src: url("./nagios-icons.eot?xohfz7#iefix") format("embedded-opentype"),
    url("./nagios-icons.ttf?xohfz7") format("truetype"),
    url("./nagios-icons.woff?xohfz7") format("woff"),
    url("./nagios-icons.svg?xohfz7#nagios-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "nagios-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.5em;
  position: relative;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

i.icon-highlight {
  margin: 2px;
}

i.icon-highlight > i {
  font-size: 0.75em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

i.icon-circle > i {
  font-size: 0.75em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
}

i > i.icon-circle {
  position: absolute;
  top: 0;
  right: -0.25em;
  font-size: 0.5em;
  filter: drop-shadow(0px 0px 1px #000);
}

.icon-add:before {
  content: "\e906";
}
.icon-add-circle:before {
  content: "\e93d";
}
.icon-alert:before {
  content: "\e907";
}
.icon-api:before {
  content: "\e93e";
}
.icon-arrow-expand:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e908";
}
.icon-arrow-two-way:before {
  content: "\e909";
}
.icon-arrow-two-way-thin:before {
  content: "\e90a";
}
.icon-arrow-up-circle:before {
  content: "\e938";
}
.icon-backups:before {
  content: "\e90b";
}
.icon-briefcase:before {
  content: "\e90c";
}
.icon-calendar:before {
  content: "\e901";
}
.icon-calendar-plus:before {
  content: "\e95f";
}
.icon-chart-bar:before {
  content: "\e93f";
}
.icon-chart-line:before {
  content: "\e924";
}
.icon-chart-pie:before {
  content: "\e92b";
}
.icon-chart-pie-2:before {
  content: "\e960";
}
.icon-check:before {
  content: "\e90d";
}
.icon-chevron-collapse:before {
  content: "\e90e";
}
.icon-chevron-double-left:before {
  content: "\e96b";
}
.icon-chevron-double-right:before {
  content: "\e96c";
}
.icon-chevron-down:before {
  content: "\e902";
}
.icon-chevron-expand:before {
  content: "\e90f";
}
.icon-chevron-left:before {
  content: "\e903";
}
.icon-chevron-right:before {
  content: "\e904";
}
.icon-chevron-up:before {
  content: "\e905";
}
.icon-circle:before {
  content: "\e943";
}
.icon-clock:before {
  content: "\e940";
}
.icon-close:before {
  content: "\e910";
}
.icon-commands:before {
  content: "\e941";
}
.icon-comment-add:before {
  content: "\e961";
}
.icon-config:before {
  content: "\e911";
}
.icon-contacts:before {
  content: "\e942";
}
.icon-cube:before {
  content: "\e96d";
}
.icon-dashboard:before {
  content: "\e944";
}
.icon-delete:before {
  content: "\e913";
}
.icon-desktop:before {
  content: "\e914";
}
.icon-disable:before {
  content: "\e962";
}
.icon-dots:before {
  content: "\e915";
}
.icon-download:before {
  content: "\e916";
}
.icon-duplicate:before {
  content: "\e963";
}
.icon-edit:before {
  content: "\e945";
}
.icon-email:before {
  content: "\e917";
}
.icon-extensions:before {
  content: "\e918";
}
.icon-external-link:before {
  content: "\e919";
}
.icon-file-settings:before {
  content: "\e946";
}
.icon-filter:before {
  content: "\e947";
}
.icon-folder:before {
  content: "\e948";
}
.icon-grid:before {
  content: "\e91a";
}
.icon-grid-outline:before {
  content: "\e91b";
}
.icon-headset:before {
  content: "\e949";
}
.icon-highlight:before {
  content: "\e91c";
}
.icon-history:before {
  content: "\e964";
}
.icon-home:before {
  content: "\e91d";
}
.icon-horizontal-fill:before {
  content: "\e91e";
}
.icon-host:before {
  content: "\e91f";
}
.icon-host-groups-2:before {
  content: "\e94a";
}
.icon-host-groups-3:before {
  content: "\e920";
}
.icon-host-small:before {
  content: "\e965";
}
.icon-ideas:before {
  content: "\e921";
}
.icon-image-outline:before {
  content: "\e922";
}
.icon-info:before {
  content: "\e94f";
}
.icon-licensing:before {
  content: "\e923";
}
.icon-log:before {
  content: "\e925";
}
.icon-magic-wand:before {
  content: "\e926";
}
.icon-mask:before {
  content: "\e966";
}
.icon-message:before {
  content: "\e967";
}
.icon-monitor:before {
  content: "\e927";
}
.icon-network:before {
  content: "\e94b";
}
.icon-network-outline:before {
  content: "\e928";
}
.icon-node-tree-2:before {
  content: "\e94c";
}
.icon-node-tree-3:before {
  content: "\e937";
}
.icon-notification:before {
  content: "\e94d";
}
.icon-notification-outline:before {
  content: "\e929";
}
.icon-pdf-download:before {
  content: "\e96a";
}
.icon-pdf-email:before {
  content: "\e950";
}
.icon-pdf-share:before {
  content: "\e951";
}
.icon-person:before {
  content: "\e92a";
}
.icon-phone:before {
  content: "\e952";
}
.icon-play:before {
  content: "\e92c";
}
.icon-plug:before {
  content: "\e953";
}
.icon-postal-mail:before {
  content: "\e954";
}
.icon-question:before {
  content: "\e92d";
}
.icon-question-circle:before {
  content: "\e92e";
}
.icon-question-filled:before {
  content: "\e968";
}
.icon-refresh:before {
  content: "\e92f";
}
.icon-search:before {
  content: "\e930";
}
.icon-search-object:before {
  content: "\e955";
}
.icon-send:before {
  content: "\e956";
}
.icon-server:before {
  content: "\e931";
}
.icon-servers:before {
  content: "\e932";
}
.icon-settings:before {
  content: "\e957";
}
.icon-settings-config:before {
  content: "\e933";
}
.icon-settings-global:before {
  content: "\e958";
}
.icon-share:before {
  content: "\e934";
}
.icon-share-outline:before {
  content: "\e959";
}
.icon-speedometer:before {
  content: "\e912";
}
.icon-stack:before {
  content: "\e95a";
}
.icon-stack-add:before {
  content: "\e95b";
}
.icon-star:before {
  content: "\e935";
}
.icon-stop:before {
  content: "\e936";
}
.icon-terminal:before {
  content: "\e95c";
}
.icon-trash:before {
  content: "\e95d";
}
.icon-upload:before {
  content: "\e939";
}
.icon-user-activity:before {
  content: "\e95e";
}
.icon-user-data:before {
  content: "\e93a";
}
.icon-view:before {
  content: "\e94e";
}
.icon-web:before {
  content: "\e93b";
}
.icon-web-global:before {
  content: "\e93c";
}
